<template>
  
    <div>
    
    
      
     
     
    <v-data-table
      v-model="selectedTableRows"
      :item-class="checkRead"
      :class="$vuetify.breakpoint.smAndDown ? $vuetify.theme.dark && 'td_background_dark table-padding' || 'td_background table-padding':''"
      :server-items-length="paginations.count"
      :items-per-page="10"
      :options.sync="options"
      :page.sync="page"
      :footer-props="footer"
      :headers="headers"
      :loading="loading"
      :items="notifications"
      no-data-text="Немає сповіщень для відображення"
      loading-text="Завантаження..."
      class="elevation-1 row-cursor mt-5"
      focusable
      :disable-sort="$vuetify.breakpoint.smAndDown"
      
    >
      <template v-slot:top>

        <v-toolbar flat color="secondary lighten-1" dark>
          
          <v-toolbar-title :class="$vuetify.breakpoint.smAndDown ? 'subtitle-1' : ''" ><h2 class="mr-6 accent--text">Сповіщення</h2></v-toolbar-title>
          
          
         
          <v-spacer />
          <template v-if="selectedTableRows.length == 0 && paginations.count > options.itemsPerPage">
            <v-btn :disabled="loading || options.page <= 1" icon @click="page -= 1">
              <v-icon>mdi-chevron-left</v-icon>
            </v-btn>
            <template v-if="paginations.count">
              <span v-if="!$vuetify.breakpoint.xs" class="text-body-2">{{ options.page }} / {{ lastPage }} ( {{ paginations.count }} )</span>
            </template>
            <v-btn :disabled="loading || paginations.count == paginations.end_index" icon @click="page += 1">
              <v-icon>mdi-chevron-right</v-icon>
            </v-btn>
          </template>
          <v-spacer />

          
          
         
          <v-btn icon  @click="$refs.addNotification.show('create')" title="Створити нагадування">
            <v-icon>mdi-plus</v-icon>
          </v-btn>    
          
          <v-btn icon :loading="loading" @click="getNotifications()" title="Поновити данні">
            <v-icon>autorenew</v-icon>
          </v-btn>
          
          <v-btn  
            icon 
            title="Фільтри пошуку"
            @click="$refs.filtersNotifications.show()"
            >
            <v-badge
            :value="countFilters > 0"
            color="accent"
            text="primary"
            :content="countFilters"
            dark
          >
            <v-icon>mdi-filter</v-icon>
            </v-badge>
          </v-btn>
            
          
          
        </v-toolbar>
        
      </template>

      <template
        v-if="countFilters > 0"
        v-slot:header="{ props: { headers } }"
      >
        <thead>
          <tr>
            <th :colspan="headers.length">
              Фільтри: 
              <keep-alive>
                <FiltersChips :filtersComponents="filtersComponents" :is_close="true" v-on:clear-filter="clearFilter" v-on:open-filters="$refs.filtersNotifications.show()"/>
              </keep-alive>
            </th>
          </tr>
        </thead>
      </template>
      
      

    <!-- ---------------------------------------- Cols ----------------------------------------- -->
    <template #item.date="{ item }">
      <span class="text-ex-space">{{  item.date | formatDate }}</span>
    </template>

    <template #item.read="{ item }">
        <v-icon v-if="item.read" color="grey">mdi-check-all</v-icon>
    </template>
    
    <template #item.address="{ item }">
        <div href="#" v-if="item.user" class="link_text" :class="{'primary--text text--lighten-1' : $vuetify.theme.dark && !item.read, 'link_text_read': item.read, }"  @click.prevent="openAbonentInfoModal(item.user && item.user.id)">{{ item.address }}</div>
        <template v-else>{{ item.address }}</template>
    </template>


    <template #item.stars="{ item }">
        <v-rating
            :color="item.read ? 'grey':'warning'"
            background-color="grey"
            half-increments
            hover
            readonly
            length="5"
            size="15"
            :value="item.stars"
            dense
        ></v-rating>
    </template>
      
    <template #item.reminder_type="{ item }">
        {{ item.reminder_type }}
        <v-chip v-if="item.for_user" x-small :color="item.read ? 'grey':'orange'" class=" white--text">Персональне</v-chip>
    </template>
      
      <template #item.notifications_settings="{ item }">
        <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn  v-bind="attrs" v-on="on"
          @click="1+1" :color="$vuetify.breakpoint.smAndDown ? 'primary lighten-1' : ''" :icon="!$vuetify.breakpoint.smAndDown" small> 
          <span v-if="$vuetify.breakpoint.smAndDown">Опції</span>
          <v-icon v-else>mdi-dots-vertical</v-icon>
        </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-if="!item.read" @click="readNotification(item)">
            <v-icon class="pr-3">mdi-check-all</v-icon>
            <v-list-item-title>Прочитано</v-list-item-title>
          </v-list-item>
          <v-list-item @click="deleteNotification(item)">
            <v-icon class="pr-3">mdi-delete</v-icon>
            <v-list-item-title>Видалити</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
        
      </template> 


    <!-- ---------------------------------------- /Cols ----------------------------------------- -->
    
        <template v-slot:footer.page-text="{pageStart, pageStop, itemsLength}">
        {{ pageStart }}-{{ pageStop }} із {{ itemsLength }}
        </template>

   
    
    </v-data-table>
    


   
    <keep-alive>
      <Filters ref="filtersNotifications" v-on:apply-filters="checkGetNotifications()" for="notifications" />
    </keep-alive>

    <keep-alive>
      <NoteModalAdd ref="addNotification" v-on:update-notification="updateTable += 1"/>
    </keep-alive>

    <keep-alive>
      <AbonentInfoModal ref="AbonentInfoModal" backTo="Сповіщення" v-on:update-data="updateTable += 1"/>
    </keep-alive>
    
    
    </div>
    
  
</template>

<script>
import axios from "axios"

import Filters from '@/components/Abonents/Filters.vue'
import FiltersChips from '@/components/Abonents/FiltersChips.vue'
import NoteModalAdd from '@/components/Notifications/NoteModalAdd.vue'
import AbonentInfoModal from '@/components/Job/AbonentInfoModal.vue'



export default {
  name: 'notifications-table',
  components: {
    Filters,
    FiltersChips,
    NoteModalAdd,
    AbonentInfoModal
  },
  props: ["user"],
  data: () => ({
    onClickRowStop: false,
    updateTable: 0,
    selectedTableRows:[],
    notifications: [],
    paginations: {}, 
    loading: false,
    options: {},
    page: 1,
    countFilters: 0,
    headers: [
      { text: '', value: 'read', sortable: false},
      { text: 'Дата', value: 'date', sortable: false},
      { text: 'Тип', value: 'reminder_type', sortable: false },
      { text: 'Оператор', value: 'operator', sortable: false },
      { text: 'Адреса', value: 'address', sortable: false },
      { text: 'Приорітет', value: 'stars', sortable: false },
      { text: 'Повідомлення', value: 'reminder_text', sortable: false},
      { text: 'Створив', value: 'user_recive', sortable: false },
      { text: '', value: 'notifications_settings', sortable: false}, 
      //{ text: '', value: 'data-table-expand' }, 
    ],
    footer: {
        "items-per-page-options": [10,25,50],
        "items-per-page-text": " ", 
        "items-per-page-all-text": "Всі", 
        "show-first-last-page": true,
        "show-current-page": true,
    },
      
  }),
  computed: {
     
      
      lastPage(){
        let rawLastPage = this.paginations.count / this.options.itemsPerPage
        return rawLastPage && Math.ceil(rawLastPage) || 1
      },
  },
  watch: {
    
    options: {
      handler (val, oldVal) {
        //
        oldVal.page && this.getNotifications();
      },
      deep: true,
    },
    selectedTableRows(){
        //console.log(this.selectedTableRows)
    },
    updateTable(){
      this.updateTable && this.getNotifications();
    },
    
  },
  mounted (){
    //console.log("mounted Notification")
    this.getNotifications()
  },
  activated() {
    
    //
  },
  deactivated(){
    //
  },
  methods: {
    checkRead(item){
      return item.read && "read--notification" || "no-read--notification"
    },
    onClickRow(){
      //
    },
    openAbonentInfoModal(paramsUrlId){
      this.onClickRowStop = true
      this.$refs.AbonentInfoModal.paramsUrlId = paramsUrlId
      this.$refs.AbonentInfoModal.dialog = true
    },
    checkGetNotifications(){
      if(this.options.page == 1){
        this.getNotifications();
      }else{
        this.page = 1;
      }
    },
    clearFilter(filter_name){
      this.$refs.filtersNotifications.filters.find(x => x.name == filter_name).value = ''
      this.$refs.filtersNotifications.apply()
    },
    getFiltersOfComponents(){
      return this.$refs.filtersNotifications.filters.filter(x => x.value && x.value != '') 
    },
    filterFormat() {
      this.countFilters = 0
      const temp = {};
      temp.itemsPerPage = this.options.itemsPerPage;
      temp.page = this.options.page;
      
      //if(this.search) temp.search = this.search
      
      /* получение значений фильтрации и просчет колличества фильтров */
      this.filtersComponents = this.getFiltersOfComponents()
      this.filtersComponents.forEach(filter => temp[filter.name] = filter.value + '');
      this.countFilters = this.filtersComponents.length
      
      return temp; 
    },
    getNotifications(){
      this.selectedTableRows = []
      this.loading = true;
      
      axios.get(`/api/reminder/get_reminders/`, {params: this.filterFormat()})
      .then( response =>{
        //console.log(response.data)
        this.notifications = response.data.results
        this.paginations = response.data.paginations
        this.$store.dispatch('notifications/fetchCountNotifications')
      })
      .catch(() => {
        //console.log(error);
        //this.errored = true;
      })
      .finally(() => (this.loading = false));
    },
    deleteNotification(item){
      this.loading = true;

      axios({
          headers: { "Content-type": "application/json; charset=UTF-8" },
          method: "DELETE",
          url: `/api/reminder/read_delete_reminders/${item.id}/`
      })
      .then(() => {
          this.$router.app.$snack.show("Видалено!");
          this.checkGetNotifications()
          this.$store.dispatch('notifications/fetchCountNotifications');
      })
      .catch(err => {
          //reject(err)
          //console.log(err);
          this.$router.app.$sheet.show("Помилка!", err);
      })
      .finally(() => (this.loading = false))
    },
    readNotification(item){
      this.loading = true;

      axios({
          headers: { "Content-type": "application/json; charset=UTF-8" },
          method: "PUT",
          url: `/api/reminder/read_delete_reminders/${item.id}/`
      })
      .then(() => {
          this.getNotifications()
          this.$store.dispatch('notifications/fetchCountNotifications');
      })
      .catch(err => {
          //reject(err)
          //console.log(err);
          this.$router.app.$sheet.show("Помилка!", err);
      })
      .finally(() => (this.loading = false))
    },
  }
}
</script>
<style scoped>
.link_text{
  cursor: pointer;
  text-decoration: none;
  color: #124988
}
.link_text_read{
  color: rgb(121, 121, 121)
}
.text-ex-space{
  white-space: nowrap !important /* Запрещаем перенос слов */
}

</style>