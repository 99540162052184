<template>
    <v-container class="notification" :style="{'max-width': '1500px'}">
      
    <TableNotifications :user="user" />
    
    </v-container>
</template>

<script>
//import axios from "axios"
import {mapGetters} from 'vuex'
import TableNotifications from '@/components/Notifications/TableNotifications.vue'

export default {
    name: 'Notification',
    components:{
      TableNotifications
    },

    data: () => ({
      loading: false,
    }),
    mounted(){
      //console.log("mounted page Notification")
    },
    computed: {
      ...mapGetters({
        user: "auth/user",
      }),
    },
    methods:{

    }

}
    

</script>
